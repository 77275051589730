@keyframes slideLeftToRight {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  /* Apply the animation to your menu */
  #kt_header_menu.slide-animation {
    animation: slideLeftToRight 1s ease-in-out; /* Adjust the duration and timing function as needed */
  }