.label {
    height: 60.68px !important; 
  }

.shadowBox {
  box-shadow:-2px 5px 10px 5px rgb(37 45 51 / 17%), 5px -3px 18px 5px rgb(37 45 51 / 17%);
}

p.no-margin {
  margin-bottom: 0; /* or margin-bottom: auto; */
}

.hrLine{
  border: none !important;
  width: 41 !important;
  height: 2 !important;
  color: "#007bfc" !important;
  background-color: "#007bfc" !important;
  margin: 0 auto !important;
}

.activeBorder{
  box-shadow: -2px 5px 10px 5px rgb(37 45 51 / 8%), 5px -3px 18px 5px rgb(37 45 51 / 8%);
  border:2px solid #3498db !important;
}

.w100{
  width: 100% !important;
}

.mhc_res_size{
  width: 828px;
}

.mhc_checkout2{
    z-index: 95 !important;
    position: fixed !important;
    top: 97px !important;
    width: 268px !important;
    left: 1151.8px !important;
}
.mhc_checkout{ 
  position: fixed !important; 
}
.plan_list_active{ 
  outline: 2px solid #009EF7; 
}
 
.border-left-right-bottom {
  border-left: 1px solid #e4e6ef !important;
  border-right: 1px solid #e4e6ef !important;
  border-bottom: 1px solid #e4e6ef !important;
}


@-webkit-keyframes loadStack {
  from {
    transform: translateX(-600%);
  }
  to {
    transform: translateX(200px);
  }
}
@keyframes loadStack {
  from {
    transform: translateX(-600%);
  }
  to {
    transform: translateX(200px);
  }
}
.loadingStack {
  background-color: lightgrey;
  border-radius: 5px;
  height: 20px;
  margin: 2em auto;
  overflow: hidden;
  position: relative;
  width: 400px;
}
.loadingStack:before {
  -webkit-animation: loadStack 2s ease-in-out infinite;
          animation: loadStack 2s ease-in-out infinite;
  background-image: repeating-linear-gradient(100deg, #1c1, #1c1 5px, #0a0 5px, #0a0 8px, #2d2 8px, #2d2 10px);
  border-radius: 5px;
  content: "";
  height: 100%;
  position: absolute;
  width: 10%;
}
 