.select-box {
    position: relative;
    display: block;
    width: 100%;
    margin: 0 auto;
    color: #60666d;
  }
  @media (min-width: 768px) {
    .select-box {
      width: 70%;
    }
  }
  @media (min-width: 992px) {
    .select-box {
      width: 50%;
    }
  }
  @media (min-width: 1200px) {
    .select-box {
      width: 100%;
    }
  }
  
  select>option{
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  color: #555;
  background-color: rgb(247, 247, 247);
  background-image: none;
  font-size: 18px;
  height: 50px;
  padding: 15px;
  border: 1px solid rgb(41, 18, 18);
  }
  
  .select-box__current {
    cursor: pointer;
    width: 100%;
    padding: 0.25rem;
    -moz-padding-start: calc(1rem - 3px);
    font-weight: 500;
    color: #5E6278;
    background-color: #ffffff;
    border: 1px solid #E4E6EF;
    border-radius: 0.475rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  }
  .select-box__current:focus + .select-box__list {
    opacity: 1;
    -webkit-animation-name: none;
            animation-name: none;
  }
  .select-box__current:focus + .select-box__list .select-box__option {
    cursor: pointer;
  }
  .select-box__current:focus .select-box__icon {
    transform: translateY(-50%) rotate(180deg);
  }
  .form-select-sm {
  border-radius: 0.475rem;
  }
  .select-box__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 13px;
    opacity: 0.3;
    transition: 0.2s ease;
  }
  .select-box__value {
    display: flex;
  }
  .select-box__input {
    display: none;
  }
  .select-box__input:checked + .select-box__input-text {
    display: flex;
  }
  .select-box__input-text {
    display: none;
    width: 100%;
    margin: 0;
    padding: 9px;
    background-color: #f5f8fa;
  
  }
  .select-box__list {
    position: absolute;
    width: 100%;
    padding: 0;
    list-style: none;
    z-index: 999;
    opacity: 0;
    -webkit-animation-name: HideList;
            animation-name: HideList;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: step-start;
            animation-timing-function: step-start;
    box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
  }
  .select-box__option {
    display: flex;
    padding: 15px;
    width:100%;
    background-color: #fff;
  }
  .select-box__option:hover, .select-box__option:focus {
    background-color: #f1faff;
    color: #009ef7;
    transition: color 0.2s ease, background-color 0.2s ease;
  }
  
  @-webkit-keyframes HideList {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0);
    }
  }
  
  @keyframes HideList {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0);
    }
  }