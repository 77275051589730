.card-header-custom {
    background-color: #ffffff;  
    padding: 16px;  
    position: relative;  
  }
  
  .card-header-custom::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;  
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
   }