.label {
    height: 60.68px !important; 
  }

.shadowBox {
  box-shadow:-2px 5px 10px 5px rgb(37 45 51 / 17%), 5px -3px 18px 5px rgb(37 45 51 / 17%);
}

p.no-margin {
  margin-bottom: 0; /* or margin-bottom: auto; */
}

.hrLine{
  border: none !important;
  width: 41 !important;
  height: 2 !important;
  color: "#007bfc" !important;
  background-color: "#007bfc" !important;
  margin: 0 auto !important;
}

.activeBorder{
  box-shadow: -2px 5px 10px 5px rgb(37 45 51 / 8%), 5px -3px 18px 5px rgb(37 45 51 / 8%);
  border:2px solid #3498db !important;
}

.w100{
  width: 100% !important;
}

.mhc_res_size{
  width: 828px;
}

.mhc_checkout2{
    z-index: 95 !important;
    position: fixed !important;
    top: 97px !important;
    width: 268px !important;
    left: 1151.8px !important;
}
.mhc_checkout{ 
  position: fixed !important; 
}
.plan_list_active{ 
  outline: 2px solid #009EF7; 
}
 