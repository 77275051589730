.logo-fade-in {
    opacity: 0; /* Start with the image hidden */
    animation: fadeInLogo 1s ease-in both;
    /* transform: translateY(20px);  
    transition: opacity 1s ease-in-out, transform 0.5s ease;  */
  }

  @keyframes fadeInLogo {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}